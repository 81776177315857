import { useState, useEffect } from "react";
import NSPLogInfo from "pages/nspLogs/NSPLogInfo";
import styled from "styled-components";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { alertAtom } from "recoil/atoms/alert";
import { themeAtom } from "recoil/atoms/theme";
import { headerAtom } from "recoil/atoms/header";
import { v4 as uuid } from "uuid";
import Modal from "components/modal/Modal";
import Box from "@mui/material/Box";
import { getData } from "utils/helpers/FetchHelper";
import { getUser } from "utils/helpers/CookiesHelper";
import { Replay } from "@mui/icons-material";
import { Button } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector } from "@mui/x-data-grid";
import { useNavigate } from "react-router";

const StyledNSPLogs = styled.div`
  box-sizing: border-box;
  height: 100%;
`;

const NSPLog = () => {
  const navigate = useNavigate();
  const setAlert = useSetRecoilState(alertAtom);
  const theme = useRecoilValue(themeAtom);
  const setHeader = useSetRecoilState(headerAtom);
  const [data, setData] = useState([]);
  const [row, setRow] = useState([]);
  const [showNspInf, setShowNspInfo] = useState(false);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "gnch_id",
      headerName: "Ref number",
      type: "string",
      flex: 1,
    },
    {
      field: "nste_name",
      headerName: "Status",
      type: "singleSelect",
      valueOptions: [...new Set(data.map((item: any) => item.nste_name))],
      flex: 1,
    },
    {
      field: "operation",
      headerName: "Operation",
      type: "string",
      flex: 1,
    },
    {
      field: "",
      headerName: "NSP code",
      type: "string",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div>
            {params.row.poc_code}-{params.row.nwk_code}
          </div>
        );
      },
    },
    {
      field: "created_date",
      headerName: "Created on",
      type: "dateTime",
      flex: 1,
      renderCell: (params: any) => {
        return <div>{`${new Date(params.row.created_date).toLocaleDateString("en-NZ")}`}</div>;
      },
    },
  ];

  useEffect(() => {
    setHeader({
      title: "NSP Logs",
      description: "",
    });
    fetchData();
    // eslint-disable-next-line
  }, [setAlert, setHeader]);

  const fetchData = () => {
    if (getUser().logged_in) {
      setLoading(true);
      getData(`/api/v1/nsp/nsp_change_log`, setData, setLoading, setAlert, () => navigate("/"));
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
        <GridToolbarFilterButton placeholder="" onPointerEnterCapture onPointerLeaveCapture />
        <GridToolbarDensitySelector placeholder="" onPointerEnterCapture onPointerLeaveCapture />
        <GridToolbarExport
          csvOptions={{
            fileName: "nsp_logs_export",
          }}
        />
        <Button variant="text" size="small" startIcon={<Replay />} onClick={() => fetchData()}>
          Refresh
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <StyledNSPLogs>
      <Box
        sx={{
          height: "100%",
          "& .super-app-theme--Accepted": {
            color: "#004209",
          },
          "& .super-app-theme--Rejected": {
            color: "#860000",
          },
          "& .super-app-theme--In progress": {
            color: "#a15600",
          },
        }}
      >
        <DataGrid
          sx={{
            paddingLeft: "1rem",
            paddingTop: "0.5rem",
            border: 0,
            borderRadius: 0,
            background: theme.clearWhite,
            color: theme.textPrimary,
            "&:hover": {
              cursor: "pointer",
            },
          }}
          getRowClassName={(params: any) => `super-app-theme--${params.row.nste_name}`}
          rows={data}
          columns={columns}
          getRowId={() => uuid()}
          disableSelectionOnClick
          components={{ Toolbar: CustomToolbar }}
          loading={loading}
          onCellClick={(e: any) => {
            setRow(e.row);
            setShowNspInfo(true);
          }}
        />
      </Box>
      <Modal onClose={() => setShowNspInfo(false)} open={showNspInf} width={"60vw"}>
        <NSPLogInfo row={row} />
      </Modal>
    </StyledNSPLogs>
  );
};

export default NSPLog;
